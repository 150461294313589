import { useState, useEffect } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

function CustomerCreate({ onCreateReviewRequest, onCreateVendor }) {

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const [requestFormData, setRequestFormData] = useState({
    title: "",
    description: "",
    compliance_requirements: "",
    due_date: null,
    template: null,
    type_of_review: "",
    vendor_contact_details: null,
  });

  const [vendorFormData, setVendorFormData] = useState({
    name: "",
    description: "",
    email: "",
    phoneNumber: "",
    address: "",
    country: "",
    website: "",
  });

  const [vendors, setVendors] = useState([]);

  const [templates, setTemplates] = useState([]);

  const [popupVisible, setPopupVisible] = useState(false);

  const reviewTypes = ["Type1", "Type2", "Type3"];

  useEffect(() => {
    (async () => {
      try {
        const responseTemplates = await axios.get(
          SERVER_URL + "template/"
        );

        const responseVendors = await axios.get(
          SERVER_URL + "vendor-contact-details"
        );

        // Store templates in state
        setTemplates(responseTemplates.data);

        // Store vendors in state
        setVendors(responseVendors.data);
      } catch (err) {
        console.error(err.message);
      }
    })();
  }, []);

  const handleRequestFormChange = (event) => {
    setRequestFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleVendorFormChange = (event) => {
    setVendorFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleRequestFormSubmit = (event) => {
    event.preventDefault();

    // Retrieve the token from the local storage (this will be needed to create a review request)
    const token = localStorage.getItem("token");

    // Parse the form data before sending it to the backend

    // Convert date into this format: YYYY-MM-DD
    const ISO = requestFormData.due_date.toISOString().split("T")[0];

    // The field "template" should have an id value (same with vendor_contact_details)
    const templateId = requestFormData.template.id;
    const vendorId = requestFormData.vendor_contact_details.id;

    const newState = { ...requestFormData };

    newState.due_date = ISO;
    newState.template = templateId;
    newState.vendor_contact_details = vendorId;

    onCreateReviewRequest(newState, token);

    // Reset the form after a submit operation
    setRequestFormData({
      title: "",
      description: "",
      compliance_requirements: "",
      due_date: null,
      template: null,
      type_of_review: "",
      vendor_contact_details: null,
    });
  };

  const handleVendorFormSubmit = async (event) => {
    event.preventDefault();

    const result = await onCreateVendor(vendorFormData);

    if (result.success) {
      // Add the new vendor to the vendors list
      const newState = [...vendors];
      newState.push(result.data);
      setVendors(newState);
    }

    // Reset the form after submit operation
    setVendorFormData({
      name: "",
      email: "",
      description: "",
      phoneNumber: "",
      address: "",
      country: "",
      website: "",
    });
  };

  return (
    <>
      <form onSubmit={handleRequestFormSubmit}>
        <div className="surface-100 px-2 py-6 md:px-4 lg:px-6">
          <div className="text-900 font-medium text-900 text-xl mb-3">
            Create a new review request
          </div>
          <p className="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            Please fill in the following fields below.
          </p>
          <div className="surface-card p-4 shadow-2 border-round">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <label htmlFor="title" className="font-medium text-900">
                  Title
                </label>
                <InputText
                  id="title"
                  name="title"
                  type="text"
                  value={requestFormData.title}
                  onChange={handleRequestFormChange}
                />
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="desc" className="font-medium text-900">
                  Description
                </label>
                <InputTextarea
                  id="desc"
                  name="description"
                  type="text"
                  value={requestFormData.description}
                  rows={5}
                  autoResize
                  onChange={handleRequestFormChange}
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label htmlFor="compliance" className="font-medium text-900">
                  Compliance requirements
                </label>
                <InputText
                  id="compliance"
                  name="compliance_requirements"
                  type="text"
                  value={requestFormData.compliance_requirements}
                  onChange={handleRequestFormChange}
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label htmlFor="due-date" className="font-medium text-900">
                  Due date
                </label>
                <Calendar
                  id="due-date"
                  name="due_date"
                  value={requestFormData.due_date}
                  dateFormat="yy/mm/dd"
                  showButtonBar
                  showIcon
                  onChange={handleRequestFormChange}
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label htmlFor="template" className="font-medium text-900">
                  Template
                </label>
                <Dropdown
                  inputId="template"
                  name="template"
                  options={templates}
                  value={requestFormData.template}
                  showClear
                  optionLabel="name"
                  placeholder="Select template"
                  onChange={handleRequestFormChange}
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="type-of-review"
                  className="font-medium text-900"
                >
                  Type of review
                </label>
                <Dropdown
                  inputId="type-of-review"
                  name="type_of_review"
                  options={reviewTypes}
                  value={requestFormData.type_of_review}
                  showClear
                  placeholder="Select type"
                  onChange={handleRequestFormChange}
                />
              </div>
              <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
              <div className="col-12 mb-4 font-medium text-900 text-lg">
                Vendor information
              </div>
              <div className="field mb-4 col-6">
                <label htmlFor="vendor" className="font-medium text-900">
                  Vendor
                </label>
                <div className="p-inputgroup">
                  <Dropdown
                    inputId="vendor"
                    name="vendor_contact_details"
                    options={vendors}
                    value={requestFormData.vendor_contact_details}
                    showClear
                    optionLabel="name"
                    placeholder="Select vendor"
                    onChange={handleRequestFormChange}
                  />
                  <Button
                    type="button"
                    icon="pi pi-plus"
                    onClick={() => setPopupVisible(true)}
                  />
                </div>
              </div>
              <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
              <div className="col-12">
                <Button type="submit" label="Create" className="w-auto mt-3" />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Dialog
        header="Add a vendor"
        visible={popupVisible}
        style={{ width: "70vw" }}
        onHide={() => setPopupVisible(false)}
        draggable={false}
        resizable={false}
      >
        <form onSubmit={handleVendorFormSubmit}>
          <div className="grid formgrid p-fluid">
            <div className="field mb-4 col-12">
              <label htmlFor="vendor-name" className="font-medium text-900">
                Vendor name
              </label>
              <InputText
                id="vendor-name"
                name="name"
                type="text"
                value={vendorFormData.name}
                onChange={handleVendorFormChange}
              />
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="vendor-desc" className="font-medium text-900">
                Vendor description
              </label>
              <InputTextarea
                id="vendor-desc"
                name="description"
                type="text"
                value={vendorFormData.description}
                rows={5}
                autoResize
                onChange={handleVendorFormChange}
              />
            </div>
            <div className="field mb-4 col-12 md:col-6">
              <label htmlFor="vendor-phone" className="font-medium text-900">
                Vendor phone number
              </label>
              <InputMask
                id="vendor-phone"
                name="phoneNumber"
                mask="(999) 999-9999"
                placeholder="(999) 999-9999"
                value={vendorFormData.phoneNumber}
                onChange={handleVendorFormChange}
              />
            </div>
            <div className="field mb-4 col-12 md:col-6">
              <label htmlFor="vendor-email" className="font-medium text-900">
                Vendor email
              </label>
              <InputText
                id="vendor-email"
                name="email"
                type="text"
                value={vendorFormData.email}
                onChange={handleVendorFormChange}
              />
            </div>
            <div className="field mb-4 col-12 md:col-6">
              <label htmlFor="vendor-address" className="font-medium text-900">
                Vendor address
              </label>
              <InputText
                id="vendor-address"
                name="address"
                type="text"
                value={vendorFormData.address}
                onChange={handleVendorFormChange}
              />
            </div>
            <div className="field mb-4 col-12 md:col-6">
              <label htmlFor="vendor-country" className="font-medium text-900">
                Vendor country
              </label>
              <InputText
                id="vendor-country"
                name="country"
                type="text"
                value={vendorFormData.country}
                onChange={handleVendorFormChange}
              />
            </div>
            <div className="field mb-4 col-12">
              <label htmlFor="vendor-website" className="font-medium text-900">
                Vendor website
              </label>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">www</span>
                <InputText
                  id="vendor-website"
                  name="website"
                  type="text"
                  value={vendorFormData.website}
                  onChange={handleVendorFormChange}
                />
              </div>
            </div>
            <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
            <Button type="submit" label="Add vendor" className="w-auto mt-3" />
          </div>
        </form>
      </Dialog>
    </>
  );
}

export default CustomerCreate;
