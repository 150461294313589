import { useNavigate, Outlet } from "react-router-dom";

function PrivateRoutes({ isAuthenticated, userRole, allowedRoles }) {
  let navigate = useNavigate();

  // Check if the user role is an allowed role
  const allowed = allowedRoles.includes(userRole);

  // Navigate user to unauthorized page if not authenticated or if their role is not allowed
  return isAuthenticated && allowed ? <Outlet /> : navigate("/unauthorized");
}

export default PrivateRoutes;
