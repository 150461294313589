import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function CustomerDashboard() {
  const [reviewRequests, setReviewRequests] = useState([]);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("token");

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          SERVER_URL + "review-requests/",
          config
        );

        setReviewRequests(response.data);
      } catch (err) {
        console.error(err.message);
      }
    })();
  }, []);

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">All review requests</span>
      <Button icon="pi pi-refresh" rounded raised />
    </div>
  );

  return (
    <>
      <div className="surface-100 mb-7">
        <div className="text-900 font-bold text-xl p-3">
          Latest review requests
        </div>
        <div className="grid">
          {reviewRequests.toReversed().map((currElement, index) => {
            if (index >= reviewRequests.length - 3) {
              return (
                <div className="col-12 lg:col-4 p-3">
                  <div className="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                    <div className="p-4">
                      <div className="flex align-items-center">
                        <span
                          className="inline-flex border-circle align-items-center justify-content-center bg-green-100 mr-3"
                          style={{ width: "38px", height: "38px" }}
                        >
                          <i className="pi pi-globe text-xl text-green-600"></i>
                        </span>
                        <span className="text-900 font-medium text-2xl">
                          {currElement.title}
                        </span>
                      </div>
                      <div className="text-900 my-3 text-xl font-medium">
                        {currElement.type_of_review}
                      </div>
                      <p className="mt-0 mb-3 text-700 line-height-3">
                        {currElement.description}
                      </p>
                    </div>
                    <div className="px-4 py-3 surface-100 text-right">
                      <Button
                        icon="pi pi-arrow-right"
                        label="More"
                        className="p-button-rounded p-button-success"
                        iconPos="right"
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      <DataTable value={reviewRequests} paginator rows={5} header={header}>
        <Column field="title" header="Title"></Column>
        <Column field="type_of_review" header="Type of review"></Column>
        <Column field="template" header="Template"></Column>
        <Column field="due_date" header="Due date"></Column>
      </DataTable>
      <Link to="/customer/create">
        <Button
          label="Create a new review request"
          icon="pi pi-angle-double-right"
          iconPos="right"
          link
        />
      </Link>
    </>
  );
}

export default CustomerDashboard;
