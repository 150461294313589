import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

function Login({ onLogin }) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [checked, setChecked] = useState(false);

  const features = [
    {
      title: "Unlimited Inbox",
      image: "live-collaboration.svg",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      title: "Data Security",
      image: "security.svg",
      text: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      title: "Cloud Backup Williams",
      image: "subscribe.svg",
      text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  ];

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await onLogin(formData);

    // If successful login, redirect user to dashboard
    if (result.success) {
      navigate("/customer");
    }
  };

  return (
    <div className="flex align-items-center min-h-screen surface-ground px-4 py-8 md:px-6 lg:px-8">
      <div className="flex flex-wrap shadow-2">
        <div className="w-full lg:w-6 px-0 py-4 lg:p-7 bg-blue-50">
          <img
            src="./bastion-700.svg"
            alt="Image"
            height="35"
            className="mb-6"
          />
          <Carousel
            value={features}
            itemTemplate={(feature) => (
              <div className="text-center mb-8">
                <img
                  src={`./${feature.image}`}
                  alt="Image"
                  className="mb-6 w-6"
                />
                <div className="mx-auto font-medium text-xl mb-4 text-blue-900">
                  {feature.title}
                </div>
                <p className="m-0 text-blue-700 line-height-3">
                  {feature.text}
                </p>
              </div>
            )}
          />
        </div>
        <div className="w-full lg:w-6 p-4 lg:p-7 surface-card">
          <div className="flex align-items-center justify-content-between mb-7">
            <span className="text-2xl font-medium text-900">
              Login to Bastion
            </span>
            <a
              tabIndex="0"
              className="font-medium text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150"
            >
              Sign up
            </a>
          </div>
          <div className="flex justify-content-between">
            <button className="p-ripple mr-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
              <i className="pi pi-facebook text-indigo-500 mr-2"></i>
              <span>Sign in With Facebook</span>
              <Ripple />
            </button>
            <button className="p-ripple ml-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
              <i className="pi pi-google text-red-500 mr-2"></i>
              <span>Sign in With Google</span>
              <Ripple />
            </button>
          </div>
          <Divider align="center" className="my-4">
            <span className="text-600 font-normal text-sm">OR</span>
          </Divider>

          <label htmlFor="email4" className="block text-900 font-medium mb-2">
            Email
          </label>
          <InputText
            name="username"
            id="email4"
            type="text"
            value={formData.username}
            placeholder="Email address"
            className="w-full mb-3 p-3"
            onChange={handleChange}
          />

          <label
            htmlFor="password4"
            className="block text-900 font-medium mb-2"
          >
            Password
          </label>
          <InputText
            name="password"
            id="password4"
            type="password"
            value={formData.password}
            placeholder="Password"
            className="w-full mb-3 p-3"
            onChange={handleChange}
          />

          <div className="flex align-items-center justify-content-between mb-6">
            <div className="flex align-items-center">
              <Checkbox
                id="rememberme"
                className="mr-2"
                checked={checked}
                onChange={(e) => setChecked(e.checked)}
              />
              <label htmlFor="rememberme">Remember me</label>
            </div>
            <a className="font-medium text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150">
              Forgot password?
            </a>
          </div>

          <Button
            label="Sign In"
            className="w-full py-3 font-medium"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
