import { useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import axios from "axios";

import Login from "./pages/Login";
import Layout from "./components/layout/Layout";
import PrivateRoutes from "./routes/PrivateRoutes";

import CustomerDashboard from "./pages/customer/CustomerDashboard";
import CustomerRequests from "./pages/customer/CustomerRequests";
import CustomerCreate from "./pages/customer/CustomerCreate";
import CustomerTeam from "./pages/customer/CustomerTeam";
import CustomerSettings from "./pages/customer/CustomerSettings";

import ConsultantDashboard from "./pages/consultant/ConsultantDashboard";
import ConsultantCases from "./pages/consultant/ConsultantCases";
import ConsultantSearch from "./pages/consultant/ConsultantSearch";
import ConsultantTeam from "./pages/consultant/ConsultantTeam";
import ConsultantSettings from "./pages/consultant/ConsultantSettings";


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [userRole, setUserRole] = useState("Customer");

  console.log(process.env);
  
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  console.log("Server URL: " + SERVER_URL);
  

  const SERVER_URL_NO_PREFIX = process.env.SERVER_URL;
  console.log("Server URL no prefix: " + SERVER_URL_NO_PREFIX);
  
  const BACKEND_URL = process.env.BACKEND_URL;
  console.log("Backend URL: " + BACKEND_URL);
  
  const handleLogin = async (formData) => {
    try {
      const response = await axios.post(
        SERVER_URL + "token/",
        formData
      );

      if (response.status === 200) {
        const accessToken = response.data.access;
        localStorage.setItem("token", accessToken);

        setIsAuthenticated(true);
      } else if (response.status === 401) {
        alert("Invalid username or password");
      } else {
        // Handle other response status codes
        alert("Login failed: Unexpected response from the server.");
      }

      return { success: true };
    } catch (error) {
      console.error(error);
      alert("An error occurred during login.");

      return { success: false };
    }
  };

  const handleCreateReviewRequest = async (formData, token) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post(
        SERVER_URL + "review-requests/create/",
        formData,
        config
      );
      alert("Successfully created review request.");
    } catch (error) {
      console.error(error);
      alert("An error occurred creating the review request.");
    }
  };

  const handleCreateVendor = async (formData) => {
    try {
      const response = await axios.post(
        SERVER_URL + "vendor-contact-details/",
        formData
      );
      alert("Successfully added vendor.");
      
      return { success: true, data: response.data };
    } catch (error) {
      console.error(error);
      alert("An error occurred creating the vendor.");

      return { success: false };
    }
  };

  const router = createBrowserRouter([
    {
      element: <Login onLogin={handleLogin} />,
      path: "/",
    },
    {
      element: (
        <PrivateRoutes
          isAuthenticated={isAuthenticated}
          userRole={userRole}
          allowedRoles={["Customer"]}
        />
      ),
      path: "/",
      children: [
        {
          element: <Layout userRole={"Customer"} />,
          path: "customer",
          children: [
            {
              element: <CustomerDashboard />,
              path: "/customer/",
            },
            {
              element: <CustomerRequests />,
              path: "requests",
            },
            {
              element: <CustomerCreate onCreateReviewRequest={handleCreateReviewRequest} onCreateVendor={handleCreateVendor} />,
              path: "create",
            },
            {
              element: <CustomerTeam />,
              path: "team",
            },
            {
              element: <CustomerSettings />,
              path: "settings",
            },
          ],
        },
      ],
    },
    {
      element: (
        <PrivateRoutes
          isAuthenticated={isAuthenticated}
          userRole={userRole}
          allowedRoles={["Consultant"]}
        />
      ),
      path: "/",
      children: [
        {
          element: <Layout userRole={"Consultant"} />,
          path: "consultant",
          children: [
            {
              element: <ConsultantDashboard />,
              path: "/consultant/",
            },
            {
              element: <ConsultantCases />,
              path: "cases",
            },
            {
              element: <ConsultantSearch />,
              path: "search",
            },
            {
              element: <ConsultantTeam />,
              path: "team",
            },
            {
              element: <ConsultantSettings />,
              path: "settings",
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
