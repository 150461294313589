import { useState, useEffect, useRef } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { Ripple } from "primereact/ripple";
import { StyleClass } from "primereact/styleclass";
import { Badge } from "primereact/badge";
import { InputText } from "primereact/inputtext";

function Layout({ userRole }) {
  // There will be different pages depending on the user's role
  let pages = [];

  if (userRole === "Customer") {
    pages = [
      {
        name: "Dashboard",
        path: "/customer",
        icon: "pi-home",
      },
      {
        name: "Requests",
        path: "/customer/requests",
        icon: "pi-list",
      },
      {
        name: "Create",
        path: "/customer/create",
        icon: "pi-plus-circle",
      },
      {
        name: "Team",
        path: "/customer/team",
        icon: "pi-users",
      },
      {
        name: "Settings",
        path: "/customer/settings",
        icon: "pi-cog",
      },
    ];
  }

  if (userRole === "Consultant") {
    pages = [
      {
        name: "Dashboard",
        path: "/consultant",
        icon: "pi-home",
      },
      {
        name: "Cases",
        path: "/consultant/cases",
        icon: "pi-briefcase",
      },
      {
        name: "Search",
        path: "/consultant/search",
        icon: "pi-search",
      },
      {
        name: "Team",
        path: "/consultant/team",
        icon: "pi-users",
      },
      {
        name: "Settings",
        path: "/consultant/settings",
        icon: "pi-cog",
      },
    ];
  }

  const [activeLink, setActiveLink] = useState("Dashboard");

  const hamburgerBtnRef = useRef(null);
  const ellipsisBtnRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    // Extract the current path from the URL
    const currentPath = location.pathname;

    // Determine which link should be active based on the current path
    const page = pages.find((pageEl) => pageEl.path === currentPath);

    if (page) {
      setActiveLink(page.name);
    } else {
      // Reset the active link if path cannot be found
      setActiveLink("");
    }
  }, [location.pathname]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div className="min-h-screen flex relative lg:static surface-ground">
      <div
        id="app-sidebar-9"
        className="h-screen surface-section hidden lg:block flex-shrink-0 sticky left-0 top-0 z-1 border-right-1 surface-border w-18rem lg:w-7rem select-none"
      >
        <div className="flex flex-column h-full">
          <div
            className="flex align-items-center justify-content-center flex-shrink-0"
            style={{ height: "60px" }}
          >
            <img src="./hyper-cyan.svg" alt="Image" height="30" />
          </div>
          <div className="mt-3">
            <ul className="list-none p-0 m-0">
              {pages.map((page) => {
                return (
                  <li>
                    <Link
                      to={page.path}
                      className={`p-ripple no-underline flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center ${
                        activeLink === page.name ? "text-cyan-600" : "text-600"
                      } border-left-2 border-transparent hover:surface-100 transition-duration-150 transition-colors`}
                      onClick={() => handleLinkClick(page.name)}
                    >
                      <i
                        className={`pi ${page.icon} mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl`}
                      ></i>
                      <span className="font-medium inline text-base lg:text-xs lg:block">
                        {page.name}
                      </span>
                      <Ripple />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="mt-auto">
            <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
            <a className="p-ripple m-3 flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:surface-200 border-round text-600 transition-duration-150 transition-colors">
              <img
                src="./avatar-f-1.png"
                className="mr-2 lg:mr-0"
                style={{ width: "32px", height: "32px" }}
                alt="avatar-f-1"
              />
              <span className="font-medium inline lg:hidden">Amy Elsner</span>
              <Ripple />
            </a>
          </div>
        </div>
      </div>
      <div className="min-h-screen flex flex-column relative flex-auto">
        <div
          className="flex justify-content-between align-items-center px-5 surface-section sticky top-0 border-bottom-1 surface-border"
          style={{ height: "60px" }}
        >
          <div className="flex">
            <StyleClass
              nodeRef={hamburgerBtnRef}
              selector="#app-sidebar-9"
              enterClassName="hidden"
              enterActiveClassName="fadeinleft"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeoutleft"
              hideOnOutsideClick
            >
              <a
                ref={hamburgerBtnRef}
                className="p-ripple cursor-pointer block lg:hidden text-700 mr-3"
              >
                <i className="pi pi-bars text-4xl"></i>
                <Ripple />
              </a>
            </StyleClass>
            <span className="p-input-icon-left">
              <i className="pi pi-search"></i>
              <InputText
                className="border-none w-10rem sm:w-20rem"
                placeholder="Search"
              />
            </span>
          </div>
          <StyleClass
            nodeRef={ellipsisBtnRef}
            selector="@next"
            enterClassName="hidden"
            enterActiveClassName="fadein"
            leaveToClassName="hidden"
            leaveActiveClassName="fadeout"
            hideOnOutsideClick
          >
            <a
              ref={ellipsisBtnRef}
              className="p-ripple cursor-pointer block lg:hidden text-700"
            >
              <i className="pi pi-ellipsis-v text-2xl"></i>
              <Ripple />
            </a>
          </StyleClass>
          <ul
            className="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
    surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static"
          >
            <li>
              <a
                className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors"
              >
                <i className="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                <span className="block lg:hidden font-medium">Inbox</span>
                <Ripple />
              </a>
            </li>
            <li>
              <a
                className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors"
              >
                <i className="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
                  <Badge severity="danger"></Badge>
                </i>
                <span className="block lg:hidden font-medium">
                  Notifications
                </span>
                <Ripple />
              </a>
            </li>
            <li className="border-top-1 surface-border lg:border-top-none">
              <a
                className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors"
              >
                <img
                  src="./avatar-f-1.png"
                  className="mr-3 lg:mr-0"
                  style={{ width: "32px", height: "32px" }}
                  alt="avatar-f-1"
                />
                <div className="block lg:hidden">
                  <div className="text-900 font-medium">Amy Elsner</div>
                  <span className="text-600 font-medium text-sm">
                    Marketing Specialist
                  </span>
                </div>
                <Ripple />
              </a>
            </li>
          </ul>
        </div>
        <div className="p-5 flex flex-column flex-auto surface-100">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
